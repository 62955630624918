import React from 'react';

export default function ShadowLinks() {
  return (
    <div className='link__mirror'>
      <p>CV</p>
      <p>Portfolio</p>
      <p>Contact</p>
    </div>
  );
}
